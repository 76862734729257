<template>
  <div id="pdfvuer">
    <div v-if="numPages > 0">
      <template v-for="i in numPages">
        <pdf :src="src" :key="i" :page="i" :resize="true" class="pdf-viewer">
        </pdf>
      </template>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <v-progress-circular
        indeterminate
        size="60"
        width="5"
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
  import pdf from 'vue-pdf';

  export default {
    name: 'AppPDFViewer',
    components: {
      pdf: pdf
    },
    props: {
      fileSource: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      numPages: undefined
    }),
    computed: {
      src() {
        return pdf.createLoadingTask(this.fileSource);
      }
    },
    mounted() {
      this.getPdf();
    },
    methods: {
      getPdf() {
        this.src.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
      }
    }
  };
</script>

<style lang="scss">
  .pdf-viewer {
    width: 100%;
  }
</style>
