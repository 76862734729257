<template>
  <div class="info-container content-wrapper">
    <div class="info-container__body mt-12">
      <div
        class="font-weight-medium text-h6 text-md-h5 text-xl-h4 mb-2 mb-sm-6 mb-md-10"
      >
        {{ $t(`label.${info}`) }}
      </div>

      <div>
        <AppPDFViewer :fileSource="fileSource"></AppPDFViewer>
        <!-- <div  class="text-center">
          {{ $t('message.sorryThereIsNoContentAtTheMomentPleaseStayTuned') }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import AppPDFViewer from '@/components/base/AppPDFViewer';
  import { Configuration } from '@/utils';

  export default {
    name: 'InfoContainer',
    components: {
      AppPDFViewer
    },
    props: {
      info: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      spacesUrl: Configuration.value('spacesUrl'),
      assetEnvironment: Configuration.value('assetEnvironment'),
      isContentExist: false
    }),
    computed: {
      fileSource() {
        let source = {
          faq: 'faq',
          termsOfUse: 'terms',
          privacyPolicy: 'privacy_policy',
          aboutUs: 'about_us',
          guardianConsent: 'consent'
        };

        // Insert random numbers to prevent content being cached
        let randomNumber =
          'random=' +
          new Date().getTime() +
          Math.floor(Math.random() * 1000000);

        return `${this.spacesUrl}/${this.assetEnvironment}/${
          source[this.info]
        }.pdf?${randomNumber}`;
      }
    },
    created() {
      this.examineFileSource();
    },
    methods: {
      async examineFileSource() {
        let url = this.fileSource;
        let vm = this;

        await axios
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              vm.isContentExist = true;
            }
          })
          .catch((e) => {
            vm.isContentExist = false;
            console.log(e);
          });
      }
    }
  };
</script>

<style lang="scss">
  .info-container {
    .info-container__head {
      .info-container__head-logo {
        max-width: 220px;
      }
    }
    .info-container__body {
      padding-bottom: 80px;
    }
  }

  @media (max-width: 1903px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 190px;
        }
      }
    }
  }
  @media (max-width: 1599px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 180px;
        }
      }
    }
  }
  @media (max-width: 1535px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 170px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 160px;
        }
      }
    }
  }
  @media (max-width: 1365px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 150px;
        }
      }
    }
  }
  @media (max-width: 1263px) {
    .info-container {
      .info-container__head {
        .info-container__head-logo {
          max-width: 140px;
        }
      }
    }
  }
</style>
